import { Text } from '@components/primitives/Text'
import type { LinkGroup } from '@modules/directoryTopics/helpers/buildInterlinkingMap'
import chunk from 'lodash/chunk'
import InterlinkingListItemLinkWrapper from './InterlinkingListItemLinkWrapper.client'

export type InterlinkingMap = {
  label: string
  linkGroup: LinkGroup | undefined
}

interface InterlinkingListProps {
  interlinkingMaps: InterlinkingMap[]
}

export default function InterlinkingList({
  interlinkingMaps,
}: InterlinkingListProps) {
  const columnCount = 4
  return (
    <div className='grid grid-cols-1 gap-4'>
      {(interlinkingMaps ?? []).map((interlinkingMap, index) => (
        <div className='mb-4 grid grid-cols-1' key={index}>
          <Text
            className={'mb-4'}
            element={'h3'}
            styleName='p-strong'
            value={interlinkingMap.label}
          />
          {chunk(interlinkingMap.linkGroup, columnCount).map(
            (linkGroup, groupIndex) => (
              <div className={'lg:flex'} key={groupIndex}>
                {linkGroup.map((link, linkIndex) => (
                  <div
                    className={'mr-2 w-full pb-2 lg:float-left lg:w-1/4'}
                    key={linkIndex}
                  >
                    <InterlinkingListItemLinkWrapper
                      path={link.path.toString()}
                    >
                      <Text
                        className='text-left text-blue900'
                        styleName='p'
                        value={link.label}
                      />
                    </InterlinkingListItemLinkWrapper>
                  </div>
                ))}
              </div>
            ),
          )}
        </div>
      ))}
    </div>
  )
}
