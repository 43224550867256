'use client'

import Link from '@components/primitives/Link'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { TrackingSources } from '@lib/tracking/TrackingSources'

export default function InterlinkingListItemLinkWrapper({
  children,
  path,
}: {
  children: React.ReactNode
  path: string
}) {
  return (
    <Link
      href={path}
      onClick={() =>
        trackEvent(TrackingEvents.INTERLINKED_TRIAL_CLICKED, {
          path: path,
          source: TrackingSources.INTERLINKING_LIST,
        })
      }
    >
      {children}
    </Link>
  )
}
