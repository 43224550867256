import { Footer } from '@components/app/Footer'
import { FontWrapper } from '@components/primitives/Font'
import { ResponsivePageMargin } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { classMerge } from '@components/utilities/classMerge'

export default function StaticPageLayoutWithResponsiveMargin({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <FontWrapper>
      <div className={classMerge('min-h-[80vh]', className)}>
        <ResponsivePageMargin>
          {children}
          <Footer />
        </ResponsivePageMargin>
      </div>
    </FontWrapper>
  )
}
