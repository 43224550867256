'use client'

import CustomErrorPage from '@components/app/CustomErrorPage'
import { getFaviconPath } from '@components/primitives/Layout/HeaderComponents/Favicon'
import StaticPageLayoutWithResponsiveMargin from '@components/primitives/Layout/StaticPageLayoutWithResponsiveMargin'

export function generateMetadata() {
  return {
    description:
      'Internal Server Error! Sorry, please try again | Power Life Sciences, Inc.',
    icons: [getFaviconPath({ inSSP: false })],
    title: 'Internal Server Error - Something went wrong',
  }
}

export default function GlobalErrorPage() {
  return (
    <StaticPageLayoutWithResponsiveMargin>
      <CustomErrorPage />
    </StaticPageLayoutWithResponsiveMargin>
  )
}
