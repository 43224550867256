import InterlinkingList from '@components/app/InterlinkingList'
import { NavBar } from '@components/app/NavBar'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { SUPPORT_EMAIL } from '@lib/messages/constants/emailAddresses'
import Routes from '@lib/routes'
import { type LinkGroup } from '@modules/directoryTopics/helpers/buildInterlinkingMap'

export default function CustomErrorPage({
  interlinkingMaps,
}: {
  interlinkingMaps?: { label: string; linkGroup: LinkGroup | undefined }[]
}) {
  return (
    <>
      <NavBar />
      <div className='flex flex-row items-center justify-center gap-4 text-center'>
        <div className='mb-24 grid grid-cols-1 gap-4'>
          <Text
            className='mb-8 mt-16 text-center'
            element='h1'
            styleName='h3'
            value='Internal Error'
          />
          <Text
            className='mx-auto text-center'
            element='p'
            styleName='p'
            value='Oops, how embarassing...'
          />
          <Text
            className='mx-auto text-center'
            element='p'
            styleName='p'
            value={
              <p>
                Something went wrong on our end and we have alerted our team to
                look into it. In the mean time, you can{' '}
                <Link href={Routes.search}>
                  <Text
                    className='inline text-primary500'
                    styleName='p'
                    value='search for a clinical trial here'
                  />
                </Link>
                . We&apos;ve got thousands to choose from, but if you run into
                any issues feel free to drop us a note at:{' '}
                <Link href={`mailto:${SUPPORT_EMAIL}`}>
                  <Text
                    className='inline text-primary500'
                    styleName='p'
                    value={SUPPORT_EMAIL}
                  />
                </Link>
                .
              </p>
            }
          />
        </div>
      </div>
      {interlinkingMaps && (
        <div className='grid max-w-3xl grid-cols-1 gap-4'>
          <InterlinkingList interlinkingMaps={interlinkingMaps} />
        </div>
      )}
    </>
  )
}
